<template>
  <div>
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-warrant-create'"
        :title="'Warrant list'"
        :button-text="'Create'"
        :show-action-button="false"
        :link-button-color="'success'"
      />
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="2"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="$t('Type to search')"
            clearable
            outlined
            hide-details
            dense
            updated
            upstream
            class="warrant-search mb-4"
            @keyup="getDataFromApi()"
            @click:clear.prevent="resetSearch()"
          >
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <v-autocomplete
            v-model="companyFilter"
            dense
            outlined
            :placeholder="$t('Company')"
            :items="companies"
            item-text="name"
            item-value="id"
            clearable
            type="search"
            :no-data-text="$t('noResults')"
            @update:search-input="filterCompanies"
            @change="getDataFromApi"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          sm="2"
        >
          <v-select
            v-model="categoryFilter"
            :placeholder="$t('general.categoryFilter')"
            :items="warrantCategories"
            :item-text="item => $t(item.name)"
            item-value="key"
            outlined
            dense
            clearable
            hide-details
            class="warrant-search mb-4"
            multiple
            @change="getDataFromApi"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="1"
        >
          <v-select
            v-model="locationFilter"
            :placeholder="$t('general.locationFilter')"
            :items="$store.getters.getStocks"
            item-text="name"
            item-value="id"
            multiple
            outlined
            dense
            clearable
            hide-details
            class="warrant-search mb-4"
            @change="getDataFromApi"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="2"
          class="mb-4"
        >
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="deadlineFilter"
                clearable
                dense
                outlined
                :placeholder="$t('Deadline filter')"
                class="w-full mb-1"
                v-bind="attrs"
                hide-details
                @click:clear.prevent="resetDeadline()"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="deadlineFilter"
              range
              locale="srLatn"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="1"
        >
          <v-select
            v-model="statusFilter"
            :placeholder="$t('Statuses')"
            :items="warrantStatuses"
            item-value="key"
            :item-text="item => $t(item.name)"
            outlined
            dense
            clearable
            hide-details
            class="warrant-search mb-4"
            @change="getDataFromApi"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="1"
        >
          <v-select
            v-model="paidFilter"
            :placeholder="$t('Is paid?')"
            :items="[{key: true, name: 'Paid'}, {key: false, name: 'Not paid'}]"
            item-value="key"
            :item-text="item => $t(item.name)"
            outlined
            dense
            clearable
            hide-details
            class="warrant-search mb-4"
            @change="getDataFromApi"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="1"
        >
          <v-btn
            color="error"
            outlined
            @click="resetFilters()"
          >
            {{ $t('Reset') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :key="$store.getters.getChangedWarrant"
        dense
        :headers="tableColumns"
        :items="warrantListTable"
        :items-per-page="getItemsPerPageFromLS()"
        :page="getPageFromLS()"
        :options.sync="options"
        :server-items-length="totalWarrants"
        :loading="loading"
        :no-results-text="$t('noResults')"
        :no-data-text="$t('noResults')"
        :footer-props="{
          'items-per-page-text': '#',
          'items-per-page-options':[10, 20, 50, 100],
          'page-text': `{0}-{1} ${$t('of')} {2}`
        }"
      >
        <template #[`item.platform_reference_number`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column font-weight-bold ms-3">
              <router-link
                :to="{ name: 'admin-warrant-create', params : { id : item.id } }"
                class="text--primary text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.platform_reference_number }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.company.name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column font-weight-bold ms-3">
              <router-link
                :to="{ name: 'admin-warrant-create', params : { id : item.id } }"
                class="text--primary text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.company.name }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.tools`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column font-weight-bold ms-3">
              <router-link
                :to="{ name: 'admin-warrant-create', params : { id : item.id } }"
                class="text--primary cursor-pointer text-decoration-none"
              >
                {{ getCategories(item.warrant_containers) }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.items`]="{item}">
          <div
            class="d-flex align-center"
          >
            <div class="d-flex flex-column font-weight-bold ms-3">
              <router-link
                :to="{ name: 'admin-warrant-create', params : { id : item.id } }"
                class="text--primary cursor-pointer text-decoration-none"
              >
                {{ getItems(item.warrant_containers) }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.warrant_date`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.warrant_date }}</span>
          </div>
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            class="v-chip-light-bg font-weight-semibold"
            :color="resolveStatusVariant(item.status)"
            :class="`${resolveStatusVariant(item.status)}--text`"
          >
            {{ $t(item.status) }}
          </v-chip>
        </template>

        <template #[`item.is_paid`]="{item}">
          <div
            class="v-chip-light-bg font-weight-semibold text-center"
            :class="item.is_paid ? 'success--text' : 'error--text'"
          >
            {{ item.is_paid ? $t('Yes') : $t('No') }}
          </div>
        </template>

        <template #[`item.is_invoiced`]="{item}">
          <div
            class="v-chip-light-bg font-weight-semibold text-center"
            :class="item.payment_method === 'cash' ? '' : item.is_invoiced ? 'success--text' : 'error--text'"
          >
            {{ item.payment_method === 'cash' ? '-' : item.is_invoiced ? $t('Yes') : $t('No') }}
          </div>
        </template>

        <template #[`item.is_delivered`]="{item}">
          <div
            class="v-chip-light-bg font-weight-semibold text-center"
            :class="item.is_delivered ? 'success--text' : 'error--text'"
          >
            {{ item.is_delivered ? $t('Yes') : $t('No') }}
          </div>
        </template>

        <template #[`item.received_at_location`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ getLocation(item.received_at_location) }}
            </div>
          </div>
        </template>

        <template #[`item.note`]="{item}">
          <div class="d-flex align-center">
            <v-tooltip
              bottom
              max-width="540"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="mr-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.note ? item.note.substr(0, 25) : '' }}...
                </div>
              </template>
              <span v-html="getNotes(item)"></span>
            </v-tooltip>
          </div>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="() => {isDeliveryModalOpen = true; selectedItem = item.id}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiTruckDeliveryOutline }}
                  </v-icon>
                  <span>{{ $t('hand-over') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :to="{name:'admin-warrant-checkcode', params:
                  {
                    id: item.id,
                    reference: item.platform_reference_number,
                    code: item.warrant_code,
                    company: item.company.name,
                    contactPerson: item.referent_name,
                    phone: item.phone,
                    shelf: item.shelf,
                    products: item.warrant_containers,
                    price: item.price
                  }
                }"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiCheckboxMarkedCircleOutline }}
                  </v-icon>
                  <span>{{ $t('print-code') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :to="{name:'admin-warrant-barcode', params:
                  {
                    id: item.id,
                    reference: item.platform_reference_number,
                    code: item.warrant_code,
                    company: item.company.name,
                    contactPerson: item.referent_name,
                    phone: item.phone,
                    shelf: item.shelf,
                    products: item.warrant_containers,
                    price: item.price
                  }
                }"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiLabelOutline }}
                  </v-icon>
                  <span>{{ $t('print label') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click.prevent="change({ id: item.id, is_delivered: !item.is_delivered })"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPackageUp }}
                  </v-icon>
                  <span>{{ !item.is_delivered ? $t('Sent') : $t('Return to delivery') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click.prevent="change({ id: item.id, is_invoiced: !item.is_invoiced })"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiStamper }}
                  </v-icon>
                  <span>{{ !item.is_invoiced ? $t('Invoiced') : $t('Return to invoicing') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click.prevent="change({ id: item.id, is_paid: !item.is_paid })"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiCurrencyEur }}
                  </v-icon>
                  <span>{{ !item.is_paid ? $t('Paid') : $t('Not paid') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :to="{name:'admin-warrant-view', params: {id: item.id }}"
                target='_blank'
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiCloudPrintOutline }}
                  </v-icon>
                  <span>{{ $t('print') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :to="{name:'admin-warrant-create', params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>{{ $t('edit') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="deleteWarrant(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ $t('delete') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="restoreWarrant(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRestoreOutline }}
                  </v-icon>
                  <span>{{ $t('general.restore') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="isDeliveryModalOpen"
      max-width="600px"
    >
      <v-card
        v-if="getSelectedWarrantItem"
        :key="isDeliveryModalOpen"
      >
        <v-card-title class="border-bottom-2px-silver">
          <span
            class="headline"
          >
            {{ $t('Delivery') }}
            ({{ getSelectedWarrantItem.platform_reference_number }})
          </span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-if="selectedItem"
              class="bordered-column py-2"
            >
              <v-row>
                <v-col
                  cols="12"
                  class="col-lg-4"
                >
                  <v-icon>{{ icons.mdiAccountOutline }}</v-icon>
                  {{ getSelectedWarrantItem.referent_name }}
                </v-col>
                <v-col
                  cols="12"
                  class="col-lg-3"
                >
                  <v-icon>{{ icons.mdiPhoneOutline }}</v-icon>
                  {{ getSelectedWarrantItem.phone }}
                </v-col>
                <v-col
                  cols="12"
                  class="col-lg-5"
                >
                  <v-icon>{{ icons.mdiEmailOutline }}</v-icon>
                  {{ getSelectedWarrantItem.email }}
                </v-col>
              </v-row>
            </v-list-item>
            <v-select
              v-if="getSelectedWarrantItem"
              v-model="getSelectedWarrantItem.payment_method"
              dense
              outlined
              :label="$t('Payment')"
              class="w-full mb-1"
              :items="$store.state.paymentMethods"
              item-value="key"
              :item-text="item => $t(item.name)"
              hide-details
            >
            </v-select>
            <v-btn
              v-if="getSelectedWarrantItem && getSelectedWarrantItem.payment_method === 'invoice'"
              key="invoicing-1"
              class="mt-2 col-6"
              :class="getSelectedWarrantItem.is_invoiced ? 'success' : 'secondary'"
              @click.prevent="() => {getSelectedWarrantItem.invoice = true; getSelectedWarrantItem.is_invoiced = !getSelectedWarrantItem.is_invoiced}"
            >
              {{ $t('Invoiced') }}
            </v-btn>
            <v-btn
              v-if="getSelectedWarrantItem && getSelectedWarrantItem.payment_method === 'invoice'"
              key="invoicing-2"
              class="mt-2 col-6"
              :class="!getSelectedWarrantItem.is_invoiced ? 'success' : 'secondary'"
              @click.prevent="() => {getSelectedWarrantItem.invoice = false; getSelectedWarrantItem.is_invoiced = !getSelectedWarrantItem.is_invoiced}"
            >
              {{ $t('Not invoiced') }}
            </v-btn>
            <v-btn
              v-if="getSelectedWarrantItem"
              key="invoicing-3"
              class="mt-2 col-6"
              :class="getSelectedWarrantItem.is_paid ? 'success' : 'secondary'"
              @click.prevent="() => {getSelectedWarrantItem.is_paid = !getSelectedWarrantItem.is_paid}"
            >
              {{ $t('Paid') }}
            </v-btn>
            <v-btn
              v-if="getSelectedWarrantItem"
              key="invoicing-4"
              class="mt-2 col-6"
              :class="!getSelectedWarrantItem.is_paid ? 'success' : 'secondary'"
              @click.prevent="() => {getSelectedWarrantItem.is_paid = !getSelectedWarrantItem.is_paid}"
            >
              {{ $t('Not paid') }}
            </v-btn>
            <v-list-item
              v-if="getSelectedWarrantItem"
              :to="{ name: 'admin-warrant-view', params: { id: getSelectedWarrantItem.id }}"
              target='_blank'
              style="padding: 0 !important;"
              key="invoicing-7"
              class="v-btn v-btn--is-elevated theme--dark v-size--default col-6 mt-2 info"
              @click="generateInvoice()"
            >
              {{ $t('Generate invoice') }}
            </v-list-item>
            <v-list-item
              v-if="getSelectedWarrantItem"
              :to="{ name: 'admin-warrant-quote', params: { id: getSelectedWarrantItem.id }}"
              target='_blank'
              style="padding: 0 !important;"
              key="invoicing-8"
              class="v-btn v-btn--is-elevated theme--dark v-size--default col-6 mt-2 info"
            >
              {{ $t('Generate quote') }}
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            outlined
            @click="change(getSelectedWarrantItem, true)"
          >
            {{ $t('deliver') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('Data successfully saved') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiArchiveOutline,
  mdiCloudPrintOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline,
  mdiFileRestoreOutline,
  mdiPlus,
  mdiCurrencyEur,
  mdiSquareEditOutline,
  mdiTruckDeliveryOutline,
  mdiLabelOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiPackageUp,
  mdiStamper,
} from '@mdi/js'
import axiosIns from '@axios'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'

// sidebar
import { formatDateToLocal } from '@/services/dateTimeService'
import { getItemsPerPageFromLS, getPageFromLS } from '@/services/localStorageService'

export default {
  name: 'WarrantList',
  components: { StandardCardHeader },
  props: ['byPost', 'byInvoice'],
  data() {
    const hasFilterOptions = localStorage.getItem('warrantFilterOptions') && JSON.parse(localStorage.getItem('warrantFilterOptions'))
    const warrantFilterOptions = hasFilterOptions || {}
    const searchQuery = (hasFilterOptions && hasFilterOptions.search) || ''
    const warrantListTable = []
    const totalWarrants = 0
    const loading = true
    const options = {}
    const isDeliveryModalOpen = false
    const selectedItem = null
    const snackbar = false

    const resolveStatusVariant = status => {
      if (status === 'Na čekanju') return 'primary'
      if (status === 'Završeno') return 'success'
      if (status === 'Aktivno') return 'warning'
      if (status === 'Arhivirano' || status === 'Izbrisano') return 'error'

      return 'primary'
    }

    const tableColumns = [
      {
        text: this.$t('Warrant number'),
        value: 'platform_reference_number',
      },
      {
        text: this.$t('Company'),
        value: 'company.name',
      },
      {
        text: this.$t('Job type'),
        value: 'tools',
        sortable: false,
      },
      {
        text: this.$t('Items'),
        value: 'items',
        sortable: false,
      },
      {
        text: this.$t('Created on'),
        value: 'created_at',
      },
      {
        text: this.$t('Deadline'),
        value: 'deadline',
      },
      {
        text: this.$t('Status'),
        value: 'status',
      },
      {
        text: this.$t('Is invoiced?'),
        value: 'is_invoiced',
      },
      {
        text: this.$t('Is paid?'),
        value: 'is_paid',
      },
      {
        text: this.$t('Is delivered?'),
        value: 'is_delivered',
      },
      {
        text: this.$t('Location'),
        value: 'received_at_location',
      },
      {
        text: this.$t('Shelf'),
        value: 'shelf',
      },
      {
        text: this.$t('Note'),
        value: 'note',
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const companyFilter = hasFilterOptions ? hasFilterOptions.company : ''
    const companies = hasFilterOptions && hasFilterOptions.company
      ? [hasFilterOptions.company]
      : []
    let categoryFilter = hasFilterOptions && hasFilterOptions.category
      ? hasFilterOptions.category
      : []
    if(this.$route.query && this.$route.query.job_type && !categoryFilter.includes(this.$route.query.job_type)) {
      categoryFilter = []
      categoryFilter.push(this.$route.query.job_type)
    }
    if(this.$route.query && this.$route.query.job_type === '') {
      categoryFilter = []
    }
    const { warrantCategories } = this.$store.state
    const statusFilter = hasFilterOptions && hasFilterOptions.status
      ? hasFilterOptions.status
      : ''
    const { warrantStatuses } = this.$store.state
    const locationFilter = hasFilterOptions && hasFilterOptions.location
      ? hasFilterOptions.location
      : ''

    let paidFilter = ''

    if(this.$route.query && this.$route.query.payment_status) {
      paidFilter = this.$route.query.payment_status
    }
    if(this.$route.query && this.$route.query.payment_status === '') {
      paidFilter = ''
    }
    return {
      searchQuery,
      warrantFilterOptions,
      snackbar,
      selectedItem,
      isDeliveryModalOpen,
      options,
      loading,
      categoryFilter,
      companyFilter,
      locationFilter,
      statusFilter,
      paidFilter,
      tableColumns,
      warrantListTable,
      totalWarrants,
      companies,
      warrantCategories,
      warrantStatuses,
      resolveStatusVariant,
      deadlineFilter: hasFilterOptions && hasFilterOptions.deadlineFilter
        ? hasFilterOptions.deadlineFilter.filter(Boolean)
        : [],
      dateMenu: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiCurrencyEur,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileDocumentEditOutline,
        mdiFileRestoreOutline,
        mdiCloudPrintOutline,
        mdiArchiveOutline,
        mdiTruckDeliveryOutline,
        mdiLabelOutline,
        mdiCheckboxMarkedCircleOutline,
        mdiPackageUp,
        mdiStamper,
      },
    }
  },
  computed: {
    getSelectedWarrantItem() {
      return this.selectedItem && this.warrantListTable.find(item => item.id === this.selectedItem)
    },
  },
  watch: {
    deadlineFilter() {
      this.getDataFromApi()
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$store.getters.getUsers.length === 0) {
      this.$store.dispatch('fetchUsers')
    }
    if (this.$store.getters.getEquipments.length === 0) {
      this.$store.dispatch('fetchEquipments')
    }
    if (this.$store.getters.getItems.length === 0) {
      this.$store.dispatch('fetchItems')
    }
    if (this.$store.getters.getStocks.length === 0) {
      this.$store.dispatch('fetchStocks')
    }
    if (this.$store.getters.getStockItemCategories.length === 0) {
      this.$store.dispatch('fetchStockItemCategories')
    }
    if(this.$route.query.job_type === '' || this.$route.query.job_type) {
      this.options.page = 1
    }
  },
  methods: {
    getPageFromLS,
    resetDeadline() {
      this.deadlineFilter = []
      this.getDataFromApi()
    },
    resetFilters() {
      this.options.page = 1
      this.companyFilter = ''
      this.locationFilter = ''
      this.categoryFilter = ''
      this.searchQuery = ''
      this.statusFilter = ''
      this.paidFilter = ''
      this.companyFilter = ''
      this.deadlineFilter = []
      if(this.$route.query.job_type === '' || this.$route.query.job_type) {
        this.$router.push({ name: 'admin-warrant-list' })
      }
    },
    resetSearch() {
      this.searchQuery = ''
      this.getDataFromApi()
    },
    getItemsPerPageFromLS,
    getNotes(item) {
      const itemNotes = item.warrant_containers.map(el => {
        if (el.note) { return el.note.trim() !== '' ? `${el.note}<br />` : '' }

        return ''
      }).filter(Boolean)

      const mainNote = `Glavna napomena: ${item.note ? item.note : ''}`
      const additionalNotes = itemNotes ? `<br /><br /> Dodatne napomene: ${itemNotes}` : ''

      return `${mainNote}${additionalNotes}`
    },
    generateInvoice() {
      if (this.selectedItem) {
        axiosIns({
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({ status: 'complete' }),
          url: `/admin/warrants/${this.selectedItem}`,
        }).then()
      }
    },
    change(input, isDelivery = false) {
      const that = this
      axiosIns({
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({
          invoice: input.payment_method === 'invoice',
          is_invoiced: input.is_invoiced,
          is_paid: input.is_paid,
          is_delivered: isDelivery || input.is_delivered,
          payment_method: input.payment_method,
        }),
        url: `/admin/warrants/${this.selectedItem || input.id}`,
      }).then(() => {
        that.getDataFromApi()
        that.snackbar = true
        if(isDelivery){
          this.isDeliveryModalOpen = false
        }
      })
    },
    getLocation(index) {
      const location = this.$store.getters.getStocks.find(stock => stock.id === +index)

      return location ? location.name : ''
    },
    getCategories(warrantContainers) {
      const self = this
      const categoriesString = warrantContainers.reduce((acc, curr) => {
        if (curr.process) {
          return `${acc} ${self.$t(curr.process[0].toUpperCase() + curr.process.slice(1))}, `
        }

        return ''
      }, '')
      if (categoriesString.length > 2) {
        return categoriesString.slice(0, categoriesString.length - 2)
      }

      return categoriesString
    },
    getItems(warrantContainers) {
      const categoriesString = warrantContainers.reduce((acc, curr) => `${acc} ${curr.name}, `, '')
      if (categoriesString.length > 2) {
        return categoriesString.slice(0, categoriesString.length - 2)
      }

      return categoriesString
    },
    filterCompanies(filter) {
      if (filter && filter.length >= 2) {
        axiosIns.get(`/admin/companies?search=${filter}&perPage=100`)
          .then(res => {
            this.companies = res.data.companies.data
          })
          .catch()
      }
    },
    getDataFromApi() {
      localStorage.setItem('itemsPerPage', `${this.options.itemsPerPage}`)
      localStorage.setItem('page', `${this.options.page}`)

      this.loading = true
      const orderBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : ''
      const orderDirection = this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : ''
      const search = this.searchQuery ?? ''
      const company = this.companyFilter ?? ''
      const status = this.statusFilter ?? ''
      const isPaid = this.paidFilter ?? ''
      const category = this.categoryFilter ?? ''
      const location = this.locationFilter ?? ''
      const paymentMethod = this.$route.query.payment_method || ''
      const fromDate = this.$route.query.from_date || ''
      const toDate = this.$route.query.to_date || ''
      const locationExternal = this.$route.query.location || ''
      const byPost = this.byPost ? 'yes' : ''
      const invoice = this.byInvoice ? 'yes' : ''
      const deadline1 = this.deadlineFilter.length > 0 ? this.deadlineFilter[0] : ''
      // eslint-disable-next-line no-nested-ternary
      const deadline2 = this.deadlineFilter.length > 1 ? this.deadlineFilter[1] : this.deadlineFilter[0] ? this.deadlineFilter[0] : ''
      this.warrantFilterOptions.search = search
      this.warrantFilterOptions.company = this.companies.find(c => +c.id === +company)
      this.warrantFilterOptions.status = status
      this.warrantFilterOptions.is_paid = isPaid
      this.warrantFilterOptions.category = category
      this.warrantFilterOptions.location = location
      this.warrantFilterOptions.deadlineFilter = this.deadlineFilter
      localStorage.setItem('warrantFilterOptions', JSON.stringify(this.warrantFilterOptions))
      axiosIns.get(`/admin/warrants?page=${this.options.page}&perPage=${this.options.itemsPerPage}&orderBy=${orderBy === 'company.name' ? 'companies.name' : orderBy}&orderDirection=${orderDirection}&search=${search}&company_id=${typeof company === 'object' ? company.id : company}&status=${status}&category=${category}&received_at_location=${location}&deadline1=${deadline1}&deadline2=${deadline2}&by_post=${byPost}&invoice=${invoice}&is_paid=${isPaid}&payment_method=${paymentMethod}&from_date=${fromDate}&to_date=${toDate}&locationExternal=${locationExternal}`)
        .then(res => {
          this.totalWarrants = res.data.warrants.total
          this.warrantListTable = res.data.warrants.data.map(item => ({
            ...item,
            status: this.$t(item.status),
            created_at: formatDateToLocal(item.created_at),
            deadline: formatDateToLocal(item.deadline),
          }))
          this.loading = false
        })
        .catch()
    },
    deleteWarrant(id) {
      axiosIns.delete(`admin/warrants/${id}`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
    restoreWarrant(id) {
      axiosIns.put(`/admin/warrants/${id}/restore`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
  },
}
</script>
